import { defineStore } from 'pinia'
// import { get } from "@tms/core"
import axios from 'axios'

export const useCodeStore = defineStore('codeStore', {
  state: () => ({
    codeList: [] as any[]
  }),
  actions: {
    async fetchCodeList () {
      try {
        const config = useRuntimeConfig()
        const AUTH_BASE_URL: string = config.public.API_HOST

        const userCodeResponse = await axios(AUTH_BASE_URL + '/code/v1/all/list')
        const response = userCodeResponse.data
        if (response.code === 0) {
          const userCodeList: any[] = response.data.codeList
          this.setCodeList([...userCodeList])
        } else {
          this.setCodeList([])
        }
      } catch (error: any) {
        this.setCodeList([])
      }
    },
    setCodeList (codeList: any[]) {
      this.codeList = codeList
    }
  },
  getters: {
    list (): any[] {
      return this.codeList
    }
  }
})
